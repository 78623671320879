import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertAgentMedicalQuoteFromDBSave } from "./medical.utils";
import agentMedicalQuoteActionTypes from "./medical.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  agentMedicalQuoteSaveSuccess,
  agentMedicalQuoteSaveFailure,
  fetchAgentMedicalQuoteFromDBSuccess,
  fetchAgentMedicalQuoteFromDBFailure,
  agentMedicalQuoteUpdateSuccess,
  agentMedicalQuoteUpdateFailure,
  agentMedicalQuoteDeleteSuccess,
  agentMedicalQuoteDeleteFailure,
} from "./medical.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchAgentMedicalQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/agent-medical-quote/${id}`);
    const medicalPolicy = yield response.data;
    yield put(fetchAgentMedicalQuoteFromDBSuccess(medicalPolicy));
  } catch (e) {
    yield put(fetchAgentMedicalQuoteFromDBFailure(e));
  }
}

function* saveAgentMedicalQuote({ payload: formData }) {
  try {
    const data = yield call(convertAgentMedicalQuoteFromDBSave, formData);
    const formDataFiles = new FormData();
    for (var i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let result = response.data ? response.data.result : [];
      let chunks = response.data ? response.data.chunks : [];
      if (result && chunks) {
        for (let m = 0; m < result.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === result[m]._id
          );
          result[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(result) ? result : [];
    });
    yield axios.post(`/api/agent-medical-quote`, data, configureAxios());
    yield put(agentMedicalQuoteSaveSuccess());
  } catch (e) {
    yield put(agentMedicalQuoteSaveFailure(e));
  }
}

function* updateAgentMedicalQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAgentMedicalQuoteFromDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length > 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let result = response.data ? response.data.result : [];
          let chunks = response.data ? response.data.chunks : [];
          if (result && chunks) {
            for (let m = 0; m < result.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === result[m]._id
              );
              result[m].chunkId = chunks[index]._id;
            }
          }
          if (data.quoteDetails && data.quoteDetails.isSelected === true) {
            data.polDocuments = Array.isArray(result)
              ? data.polDocuments.concat(result)
              : [];
          } else {
            data.documents = Array.isArray(result)
              ? data.documents.concat(result)
              : [];
          }
        });
      yield axios.patch(
        `/api/agent-medical-quote/${id}`,
        data,
        configureAxios()
      );
      yield put(agentMedicalQuoteUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      axios.patch(`/api/agent-medical-quote/${id}`, data, configureAxios());
      yield put(agentMedicalQuoteUpdateSuccess());
    }
  } catch (e) {
    yield put(agentMedicalQuoteUpdateFailure(e));
  }
}

function* deleteAgentMedicalQuote({ payload: { id, pId } }) {
  try {
    axios.delete(
      `/api/agent-medical-quote/${id}`,
      { params: { primeMemberId: pId } },
      configureAxios()
    );
    yield put(agentMedicalQuoteDeleteSuccess());
  } catch (e) {
    yield put(agentMedicalQuoteDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    agentMedicalQuoteActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchAgentMedicalQuoteFromDB() {
  yield takeEvery(
    agentMedicalQuoteActionTypes.FETCH_AGENT_MEDICAL_QUOTE_FROM_DB_START,
    fetchAgentMedicalQuoteFromDB
  );
}

function* onAgentMedicalQuoteSave() {
  yield takeEvery(
    agentMedicalQuoteActionTypes.START_AGENT_MEDICAL_QUOTE_SAVE,
    saveAgentMedicalQuote
  );
}

function* onAgentMedicalQuoteUpdate() {
  yield takeEvery(
    agentMedicalQuoteActionTypes.START_AGENT_MEDICAL_QUOTE_UPDATE,
    updateAgentMedicalQuote
  );
}

function* onDeleteAgentMedicalQuote() {
  yield takeEvery(
    agentMedicalQuoteActionTypes.START_AGENT_MEDICAL_QUOTE_DELETE,
    deleteAgentMedicalQuote
  );
}

export function* agentMedicalQuoteSagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onAgentMedicalQuoteSave),
    call(onFetchAgentMedicalQuoteFromDB),
    call(onAgentMedicalQuoteUpdate),
    call(onDeleteAgentMedicalQuote),
  ]);
}
