import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertAgentVehicleQuoteForDBSave } from "./vehicle.utils";
import agentVehicleQuoteActionTypes from "./vehicle.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  agentVehicleQuoteSaveSuccess,
  agentVehicleQuoteSaveFailure,
  fetchAgentVehicleQuoteFromDBSuccess,
  fetchAgentVehicleQuoteFromDBFailure,
  agentVehicleQuoteUpdateSuccess,
  agentVehicleQuoteUpdateFailure,
  agentVehicleQuoteDeleteSuccess,
  agentVehicleQuoteDeleteFailure,
} from "./vehicle.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchAgentVehicleQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/agent-vehicle-quote/${id}`);
    const agentVehicleQuote = yield response.data;
    yield put(fetchAgentVehicleQuoteFromDBSuccess(agentVehicleQuote));
  } catch (e) {
    yield put(fetchAgentVehicleQuoteFromDBFailure(e));
  }
}

function* saveAgentVehicleQuote({ payload: formData }) {
  try {
    const data = yield call(convertAgentVehicleQuoteForDBSave, formData);
    const formDataFiles = new FormData();
    for (var i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let result = response.data ? response.data.result : [];
      let chunks = response.data ? response.data.chunks : [];
      if (result && chunks) {
        for (let m = 0; m < result.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === result[m]._id
          );
          result[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(result) ? result : [];
    });
    yield axios.post(`/api/agent-vehicle-quote`, data, configureAxios());
    yield put(agentVehicleQuoteSaveSuccess());
  } catch (e) {
    yield put(agentVehicleQuoteSaveFailure(e));
  }
}

function* updateAgentVehicleQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAgentVehicleQuoteForDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length > 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let result = response.data ? response.data.result : [];
          let chunks = response.data ? response.data.chunks : [];
          if (result && chunks) {
            for (let m = 0; m < result.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === result[m]._id
              );
              result[m].chunkId = chunks[index]._id;
            }
          }
          if (data.quoteDetails && data.quoteDetails.isSelected === true) {
            data.polDocuments = Array.isArray(result)
              ? data.polDocuments.concat(result)
              : [];
          } else {
            data.documents = Array.isArray(result)
              ? data.documents.concat(result)
              : [];
          }
        });
      yield axios.patch(
        `/api/agent-vehicle-quote/${id}`,
        data,
        configureAxios()
      );
      yield put(agentVehicleQuoteUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      yield axios.patch(
        `/api/agent-vehicle-quote/${id}`,
        data,
        configureAxios()
      );
      yield put(agentVehicleQuoteUpdateSuccess());
    }
  } catch (e) {
    yield put(agentVehicleQuoteUpdateFailure(e));
  }
}

function* deleteAgentVehicleQuote({ payload: { id, pId } }) {
  try {
    axios.delete(
      `/api/agent-vehicle-quote/${id}`,
      { params: { primeMemberId: pId } },
      configureAxios()
    );
    yield put(agentVehicleQuoteDeleteSuccess());
  } catch (e) {
    yield put(agentVehicleQuoteDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    agentVehicleQuoteActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchAgentVehicleQuoteFromDB() {
  yield takeEvery(
    agentVehicleQuoteActionTypes.FETCH_AGENT_VEHICLE_QUOTE_FROM_DB_START,
    fetchAgentVehicleQuoteFromDB
  );
}

function* onAgentVehicleQuoteSave() {
  yield takeEvery(
    agentVehicleQuoteActionTypes.START_AGENT_VEHICLE_QUOTE_SAVE,
    saveAgentVehicleQuote
  );
}

function* onAgentVehicleQuoteUpdate() {
  yield takeEvery(
    agentVehicleQuoteActionTypes.START_AGENT_VEHICLE_QUOTE_UPDATE,
    updateAgentVehicleQuote
  );
}

function* onDeleteAgentVehicleQuote() {
  yield takeEvery(
    agentVehicleQuoteActionTypes.START_AGENT_VEHICLE_QUOTE_DELETE,
    deleteAgentVehicleQuote
  );
}

export function* agentVehicleQuoteSagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onAgentVehicleQuoteSave),
    call(onFetchAgentVehicleQuoteFromDB),
    call(onAgentVehicleQuoteUpdate),
    call(onDeleteAgentVehicleQuote),
  ]);
}
