import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
// import Login from '../login/login.component';
// import Registration from '../registration/registration.component';
import QuoteRequestsSteps from '../quote-requests-steps/quote-requests-steps.component';
import './showcase.styles.css';

const Showcase = ({ showLoginOrRegistrationView }) => {
  return (
    <section id='showcase-section'>
      <div className='home-inner'>
        <Container>
          <h1 className='header-m text-center'>Simplify Your Insurance Shopping with InsurEasier -</h1>
          <h1 className='header-m text-center'>The Ultimate Destination for All Your Coverage Needs!</h1>
          {/* <h2 className='display-b text-center'>Easier Insurance Shopping and Buying</h2> */}
          <Row className='align-items-center justify-content-center mt-5 pt-2'>
            <Col lg={4} sm={{ order: 3 }} className='mb-4'>
              {/* {showLoginOrRegistrationView === 'login' ? (
                <Login />
              ) : (
                <Registration />
              )} */}
              <p className='font-size-l font-weight-normal'>Thank you for visiting! Please excuse us as we are gathering agents and not yet offering quotes and policies.
              If you're an agent, please click the <a href='/agent-home' className='text-pink'>For Agents</a> link at the top right to register.</p>
            </Col>
            <Col lg={2} sm={{ order: 2 }} md={1}>
            </Col>
            <Col lg={6} sm={{ order: 1 }} md={7}>
              <QuoteRequestsSteps />
            </Col>
          </Row> 
        </Container>
      </div>
    </section>
  );
};

const mapStateToProps = ({ navigation: { showLoginOrRegistrationView } }) => ({
  showLoginOrRegistrationView,
});

export default connect(mapStateToProps)(Showcase);