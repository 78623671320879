import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertAgentLifeQuoteFromDBSave } from "./life.utils";
import agentLifeQuoteActionTypes from "./life.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  agentLifeQuoteSaveSuccess,
  agentLifeQuoteSaveFailure,
  fetchAgentLifeQuoteFromDBSuccess,
  fetchAgentLifeQuoteFromDBFailure,
  agentLifeQuoteUpdateSuccess,
  agentLifeQuoteUpdateFailure,
  agentLifeQuoteDeleteSuccess,
  agentLifeQuoteDeleteFailure,
} from "./life.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchAgentLifeQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/agent-life-quote/${id}`);
    const lifeQuote = yield response.data;
    yield put(fetchAgentLifeQuoteFromDBSuccess(lifeQuote));
  } catch (e) {
    yield put(fetchAgentLifeQuoteFromDBFailure(e));
  }
}

function* saveAgentLifeQuote({ payload: formData }) {
  try {
    const data = yield call(convertAgentLifeQuoteFromDBSave, formData);
    const formDataFiles = new FormData();
    for (var i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let result = response.data ? response.data.result : [];
      let chunks = response.data ? response.data.chunks : [];
      if (result && chunks) {
        for (let m = 0; m < result.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === result[m]._id
          );
          result[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(result) ? result : [];
    });
    yield axios.post(`/api/agent-life-quote`, data, configureAxios());
    yield put(agentLifeQuoteSaveSuccess());
  } catch (e) {
    yield put(agentLifeQuoteSaveFailure(e));
  }
}

function* updateAgentLifeQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAgentLifeQuoteFromDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length > 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let result = response.data ? response.data.result : [];
          let chunks = response.data ? response.data.chunks : [];
          if (result && chunks) {
            for (let m = 0; m < result.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === result[m]._id
              );
              result[m].chunkId = chunks[index]._id;
            }
          }
          if (data.quoteDetails && data.quoteDetails.isSelected === true) {
            data.polDocuments = Array.isArray(result)
              ? data.polDocuments.concat(result)
              : [];
          } else {
            data.documents = Array.isArray(result)
              ? data.documents.concat(result)
              : [];
          }
        });
      yield axios.patch(`/api/agent-life-quote/${id}`, data, configureAxios());
      yield put(agentLifeQuoteUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      yield axios.patch(`/api/agent-life-quote/${id}`, data, configureAxios());
      yield put(agentLifeQuoteUpdateSuccess());
    }
  } catch (e) {
    yield put(agentLifeQuoteUpdateFailure(e));
  }
}

function* deleteAgentLifeQuote({ payload: { id, pId } }) {
  try {
    axios.delete(
      `/api/agent-life-quote/${id}`,
      { params: { primeMemberId: pId } },
      configureAxios()
    );
    yield put(agentLifeQuoteDeleteSuccess());
  } catch (e) {
    yield put(agentLifeQuoteDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    agentLifeQuoteActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchAgentLifeQuoteFromDB() {
  yield takeEvery(
    agentLifeQuoteActionTypes.FETCH_AGENT_LIFE_QUOTE_FROM_DB_START,
    fetchAgentLifeQuoteFromDB
  );
}

function* onAgentLifeQuoteSave() {
  yield takeEvery(
    agentLifeQuoteActionTypes.START_AGENT_LIFE_QUOTE_SAVE,
    saveAgentLifeQuote
  );
}

function* onAgentLifeQuoteUpdate() {
  yield takeEvery(
    agentLifeQuoteActionTypes.START_AGENT_LIFE_QUOTE_UPDATE,
    updateAgentLifeQuote
  );
}

function* onDeleteAgentLifeQuote() {
  yield takeEvery(
    agentLifeQuoteActionTypes.START_AGENT_LIFE_QUOTE_DELETE,
    deleteAgentLifeQuote
  );
}

export function* agentLifeQuoteSagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onAgentLifeQuoteSave),
    call(onFetchAgentLifeQuoteFromDB),
    call(onAgentLifeQuoteUpdate),
    call(onDeleteAgentLifeQuote),
  ]);
}
