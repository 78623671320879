// centralized redux store for client state management
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootSaga from './root-saga';
import rootReducer from './root.reducer';

// define intial state for the app
const INITIAL_STATE = {};

// connect all midlleware for use later on in configuring store

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, thunk];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

// configure store
const store = createStore(
  rootReducer,
  INITIAL_STATE,
  composeWithDevTools(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

// Persist state to local storage
const persistor = persistStore(store);

export { store, persistor };
