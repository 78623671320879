import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertMemberVehiclePolicyForDBSave } from "./vehicle.utils";
import memberVehiclePolicyActionTypes from "./vehicle.types";
import {
  loadVehicleMakeFromDBFailure,
  loadVehicleMakeFromDBSuccess,
  loadVehicleModelsFromDBFailure,
  loadVehicleModelsFromDBSuccess,
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  fetchVehiclesFromDBSuccess,
  fetchVehiclesFromDBFailure,
  vehiclePolicySaveSuccess,
  vehiclePolicySaveFailure,
  vehiclePolicyUpdateSuccess,
  vehiclePolicyUpdateFailure,
  vehiclePolicyDeleteSuccess,
  vehiclePolicyDeleteFailure,
  fetchVehiclePolicyFromDBSuccess,
  fetchVehiclePolicyFromDBFailure,
} from "./vehicle.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* loadVehicleMakesFromDB() {
  try {
    const url = "./db/vehicle-makes.json";
    const response = yield axios.get(url);
    const makes = yield response.data.Results.map(({ Make_Name }) => Make_Name);
    yield put(loadVehicleMakeFromDBSuccess(makes));
  } catch (e) {
    yield put(loadVehicleMakeFromDBFailure(e));
  }
}

function* loadVehicleModelsFromDB({ payload: selectedMake }) {
  try {
    const url = `https://vpic.nhtsa.dot.gov/api//vehicles/GetModelsForMake/${selectedMake}?format=json`;
    const response = yield axios.get(url);
    const models = yield response.data.Results.map(
      ({ Model_Name }) => Model_Name
    );
    yield put(loadVehicleModelsFromDBSuccess(models));
  } catch (e) {
    yield put(loadVehicleModelsFromDBFailure(e));
  }
}

function* fetchVehiclesFromDB() {
  try {
    const response = yield axios.get(`/api/vehicles`);
    const vehicles = yield response.data;
    yield put(fetchVehiclesFromDBSuccess(vehicles));
  } catch (e) {
    yield put(fetchVehiclesFromDBFailure(e));
  }
}

function* fetchVehiclePolicyFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/member-vehicle-policy/${id}`);
    const vehiclePolicy = yield response.data;
    yield put(fetchVehiclePolicyFromDBSuccess(vehiclePolicy));
  } catch (e) {
    yield put(fetchVehiclePolicyFromDBFailure(e));
  }
}

function* saveVehiclePolicy({ payload: formData }) {
  try {
    const data = yield call(convertMemberVehiclePolicyForDBSave, formData);
    const formDataFiles = new FormData();
    for (var i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let result = response.data ? response.data.result : [];
      let chunks = response.data ? response.data.chunks : [];
      if (result && chunks) {
        for (let m = 0; m < result.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === result[m]._id
          );
          result[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(result) ? result : [];
      axios.post(`/api/member-vehicle-policy`, data, configureAxios());
    });
    yield put(vehiclePolicySaveSuccess());
  } catch (e) {
    yield put(vehiclePolicySaveFailure(e));
  }
}

function* updateVehiclePolicy({ payload: { id, formData } }) {
  try {
    const data = yield call(convertMemberVehiclePolicyForDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length > 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let result = response.data ? response.data.result : [];
          let chunks = response.data ? response.data.chunks : [];
          if (result && chunks) {
            for (let m = 0; m < result.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === result[m]._id
              );
              result[m].chunkId = chunks[index]._id;
            }
          }
          data.documents = Array.isArray(result)
            ? data.documents.concat(result)
            : [];
          axios.patch(
            `/api/member-vehicle-policy/${id}`,
            data,
            configureAxios()
          );
        });
      yield put(vehiclePolicyUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      axios.patch(`/api/member-vehicle-policy/${id}`, data, configureAxios());
      yield put(vehiclePolicyUpdateSuccess());
    }
  } catch (e) {
    yield put(vehiclePolicyUpdateFailure(e));
  }
}

function* deleteVehiclePolicy({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/member-vehicle-policy/${id}`,
      { params: { primeMemberId: PId } },
      configureAxios()
    );
    yield put(vehiclePolicyDeleteSuccess());
  } catch (e) {
    yield put(vehiclePolicyDeleteFailure(e));
  }
}

function* onStartLoadingVehicleMakesFromDB() {
  yield takeEvery(
    memberVehiclePolicyActionTypes.LOAD_VEHICLE_MAKE_FROM_DB_START_POLICY,
    loadVehicleMakesFromDB
  );
}

function* onStartLoadingVehicleModelsFromDB() {
  yield takeEvery(
    memberVehiclePolicyActionTypes.LOAD_VEHICLE_MODELS_FROM_DB_START,
    loadVehicleModelsFromDB
  );
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    memberVehiclePolicyActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchVehiclesFromDB() {
  yield takeEvery(
    memberVehiclePolicyActionTypes.FETCH_VEHICLES_FROM_DB_START,
    fetchVehiclesFromDB
  );
}

function* onFetchVehiclePolicyFromDB() {
  yield takeEvery(
    memberVehiclePolicyActionTypes.FETCH_VEHICLE_POLICY_FROM_DB_START,
    fetchVehiclePolicyFromDB
  );
}

function* onVehiclePolicySaveStart() {
  yield takeEvery(
    memberVehiclePolicyActionTypes.START_VEHICLE_POLICY_SAVE,
    saveVehiclePolicy
  );
}

function* onVehiclePolicyUpdateStart() {
  yield takeEvery(
    memberVehiclePolicyActionTypes.START_VEHICLE_POLICY_UPDATE,
    updateVehiclePolicy
  );
}

function* onVehiclePolicyDeleteStart() {
  yield takeEvery(
    memberVehiclePolicyActionTypes.START_VEHICLE_POLICY_DELETE,
    deleteVehiclePolicy
  );
}

export function* memberVehiclePolicySagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onFetchVehiclesFromDB),
    call(onVehiclePolicySaveStart),
    call(onStartLoadingVehicleMakesFromDB),
    call(onStartLoadingVehicleModelsFromDB),
    call(onFetchVehiclePolicyFromDB),
    call(onVehiclePolicyUpdateStart),
    call(onVehiclePolicyDeleteStart),
  ]);
}
