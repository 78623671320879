import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertMemberPropertyPolicyFromDBSave } from "./property.utils";
import memberPropertyPolicyActionTypes from "./property.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  fetchPropertiesFromDBSuccess,
  fetchPropertiesFromDBFailure,
  fetchPropertyPolicyFromDBSuccess,
  fetchPropertyPolicyFromDBFailure,
  propertyPolicySaveSuccess,
  propertyPolicySaveFailure,
  propertyPolicyUpdateSuccess,
  propertyPolicyUpdateFailure,
  propertyPolicyDeleteSuccess,
  propertyPolicyDeleteFailure,
} from "./property.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchPropertiesFromDB() {
  try {
    const response = yield axios.get(`/api/properties`);
    const properties = yield response.data;
    yield put(fetchPropertiesFromDBSuccess(properties));
  } catch (e) {
    yield put(fetchPropertiesFromDBFailure(e));
  }
}

function* fetchPropertyPolicyFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/member-property-policy/${id}`);
    const propertyPolicy = yield response.data;
    yield put(fetchPropertyPolicyFromDBSuccess(propertyPolicy));
  } catch (e) {
    yield put(fetchPropertyPolicyFromDBFailure(e));
  }
}

function* savePropertyPolicy({ payload: formData }) {
  try {
    const data = yield call(convertMemberPropertyPolicyFromDBSave, formData);
    const formDataFiles = new FormData();
    for (let i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let result = response.data ? response.data.result : [];
      let chunks = response.data ? response.data.chunks : [];
      if (result && chunks) {
        for (let m = 0; m < result.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === result[m]._id
          );
          result[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(result) ? result : [];
      axios.post(`/api/member-property-policy`, data, configureAxios());
    });
    yield put(propertyPolicySaveSuccess());
  } catch (e) {
    yield put(propertyPolicySaveFailure(e));
  }
}

function* updatePropertyPolicy({ payload: { id, formData } }) {
  try {
    const data = yield call(convertMemberPropertyPolicyFromDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length > 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let result = response.data ? response.data.result : [];
          let chunks = response.data ? response.data.chunks : [];
          if (result && chunks) {
            for (let m = 0; m < result.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === result[m]._id
              );
              result[m].chunkId = chunks[index]._id;
            }
          }
          data.documents = Array.isArray(result)
            ? data.documents.concat(result)
            : [];
          axios.patch(
            `/api/member-property-policy/${id}`,
            data,
            configureAxios()
          );
        });
      yield put(propertyPolicyUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      axios.patch(`/api/member-property-policy/${id}`, data, configureAxios());
      yield put(propertyPolicyUpdateSuccess());
    }
  } catch (e) {
    yield put(propertyPolicyUpdateFailure(e));
  }
}

function* deletePropertyPolicy({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/member-property-policy/${id}`,
      {
        params: {
          primeMemberId: PId,
        },
      },
      configureAxios()
    );
    yield put(propertyPolicyDeleteSuccess());
  } catch (e) {
    yield put(propertyPolicyDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    memberPropertyPolicyActionTypes.LOAD_STATES_PROPERTY_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchPropertiesFromDB() {
  yield takeEvery(
    memberPropertyPolicyActionTypes.FETCH_PROPERTIES_FROM_DB_START,
    fetchPropertiesFromDB
  );
}

function* onFetchPropertyPolicyFromDB() {
  yield takeEvery(
    memberPropertyPolicyActionTypes.FETCH_PROPERTY_POLICY_FROM_DB_START,
    fetchPropertyPolicyFromDB
  );
}

function* onPropertyPolicySaveStart() {
  yield takeEvery(
    memberPropertyPolicyActionTypes.START_PROPERTY_POLICY_SAVE,
    savePropertyPolicy
  );
}

function* onPropertyPolicyUpdateStart() {
  yield takeEvery(
    memberPropertyPolicyActionTypes.START_PROPERTY_POLICY_UPDATE,
    updatePropertyPolicy
  );
}

function* onPropertyPolicyDeleteStart() {
  yield takeEvery(
    memberPropertyPolicyActionTypes.START_PROPERTY_POLICY_DELETE,
    deletePropertyPolicy
  );
}

export function* memberPropertyPolicySagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onFetchPropertiesFromDB),
    call(onFetchPropertyPolicyFromDB),
    call(onPropertyPolicySaveStart),
    call(onPropertyPolicyUpdateStart),
    call(onPropertyPolicyDeleteStart),
  ]);
}
