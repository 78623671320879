import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertMemberAnnuityPolicyFromDBSave } from "./annuity.utils";
import memberAnnuityPolicyActionTypes from "./annuity.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  annuityPolicySaveSuccess,
  annuityPolicySaveFailure,
  fetchMembersFromDBSuccess,
  fetchMembersFromDBFailure,
  fetchAnnuityPolicyFromDBSuccess,
  fetchAnnuityPolicyFromDBFailure,
  annuityPolicyUpdateSuccess,
  annuityPolicyUpdateFailure,
  annuityPolicyDeleteSuccess,
  annuityPolicyDeleteFailure,
} from "./annuity.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchMembersFromDB() {
  try {
    const response = yield axios.get(`/api/members`);
    const members = yield response.data;
    yield put(fetchMembersFromDBSuccess(members));
  } catch (e) {
    yield put(fetchMembersFromDBFailure(e));
  }
}

function* fetchAnnuityPolicyFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/member-annuity-policy/${id}`);
    const annuityPolicy = yield response.data;
    yield put(fetchAnnuityPolicyFromDBSuccess(annuityPolicy));
  } catch (e) {
    yield put(fetchAnnuityPolicyFromDBFailure(e));
  }
}

function* saveAnnuityPolicy({ payload: formData }) {
  try {
    const data = yield call(convertMemberAnnuityPolicyFromDBSave, formData);
    const formDataFiles = new FormData();
    for (var i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let result = response.data ? response.data.result : [];
      let chunks = response.data ? response.data.chunks : [];
      if (result && chunks) {
        for (let m = 0; m < result.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === result[m]._id
          );
          result[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(result) ? result : [];
      axios.post(`/api/member-annuity-policy`, data, configureAxios());
    });
    yield put(annuityPolicySaveSuccess());
  } catch (e) {
    yield put(annuityPolicySaveFailure(e));
  }
}

function* updateAnnuityPolicy({ payload: { id, formData } }) {
  try {
    const data = yield call(convertMemberAnnuityPolicyFromDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length > 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let result = response.data ? response.data.result : [];
          let chunks = response.data ? response.data.chunks : [];
          if (result && chunks) {
            for (let m = 0; m < result.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === result[m]._id
              );
              result[m].chunkId = chunks[index]._id;
            }
          }
          data.documents = Array.isArray(result)
            ? data.documents.concat(result)
            : [];
          axios.patch(
            `/api/member-annuity-policy/${id}`,
            data,
            configureAxios()
          );
        });
      yield put(annuityPolicyUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      axios.patch(`/api/member-annuity-policy/${id}`, data, configureAxios());
      yield put(annuityPolicyUpdateSuccess());
    }
  } catch (e) {
    yield put(annuityPolicyUpdateFailure(e));
  }
}

function* deleteAnnuityPolicy({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/member-annuity-policy/${id}`,
      { params: { primeMemberId: PId } },
      configureAxios()
    );
    yield put(annuityPolicyDeleteSuccess());
  } catch (e) {
    yield put(annuityPolicyDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    memberAnnuityPolicyActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchMembersFromDB() {
  yield takeEvery(
    memberAnnuityPolicyActionTypes.FETCH_MEMBERS_FROM_DB_START,
    fetchMembersFromDB
  );
}

function* onFetchAnnuityFromDB() {
  yield takeEvery(
    memberAnnuityPolicyActionTypes.FETCH_ANNUITY_POLICY_FROM_DB_START,
    fetchAnnuityPolicyFromDB
  );
}

function* onAnnuityPolicySave() {
  yield takeEvery(
    memberAnnuityPolicyActionTypes.START_ANNUITY_POLICY_SAVE,
    saveAnnuityPolicy
  );
}

function* onAnnuityPolicyUpdate() {
  yield takeEvery(
    memberAnnuityPolicyActionTypes.START_ANNUITY_POLICY_UPDATE,
    updateAnnuityPolicy
  );
}

function* onDeleteAnnuityPolicy() {
  yield takeEvery(
    memberAnnuityPolicyActionTypes.START_ANNUITY_POLICY_DELETE,
    deleteAnnuityPolicy
  );
}

export function* memberAnnuityPolicySagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onAnnuityPolicySave),
    call(onFetchMembersFromDB),
    call(onFetchAnnuityFromDB),
    call(onAnnuityPolicyUpdate),
    call(onDeleteAnnuityPolicy),
  ]);
}
