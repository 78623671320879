import React from 'react';

const AgentPrivacy = () => {
  return (
    <>
      <h4 className='py-3'>Agent Privacy Policy</h4>
      <p>
        The way we gather, use, disclose, and manage your and the Users’ Data
      </p>
      <h6>1. Definitions</h6>
      <ul>
        <li>
          a. “InsurEasier,” “we,” “us,” and “our” all refer to the Limited Liability
          Company, InsurEasier, as an entity, and it’s designated, participating
          partners.
        </li>
        <li>
          b. “Agent,” “Agents,” “you,” and “your” all refer to any Agent, Agency,
          Brokerage, Producer, or entity that references, visits, or utilizes the
          InsurEasier site within the Agent portal.
        </li>
        <li>
          c. A “User” refers to someone who references, visits, or utilizes this site,
          within the initial and Member portals, including Members who store
          information or request quotes from the Agents on this site.
        </li>
        <li>
          d. A “Member” or “Members” is any User who accesses the Member portal of
          the InsurEasier site.
        </li>
        <li>
          e. “Data” is any information used or gathered on or through this site, in any
          form (whether written, coded, in media, or any other form).
        </li>
        <li>
          f. “Personal Data” is Data entered by and belonging to you or a User that
          can be used to directly identify you or a User.
        </li>
        <li>
          g. A “Carrier” is an insurance company or provider from which quotes and
          policies can be distributed.
        </li>
      </ul>
      <h6>2. Data Collected</h6>
      <p>
        This includes any Data requested by this site or by Users
        utilizing this site. Agents are to add policy Data, pertaining to a User, upon the
        creation of a new policy, for that User and Agent to view and reference. And, you
        are free to add any additional Data pertaining to insurance that you would like
        and is pertinent (refer to Section 8 of the Terms and Conditions - Limitations - for
        what is acceptable and what is not).
      </p>
      <h6>3. Verification</h6>
      <p>
        We reserve the right to verify your identity and other necessary
        information through the use of your Personal Data. In fact, we will verify that you
        are an active, licensed Agent before you can utilize this site.
      </p>
      <h6>4. Data Permissions</h6>
      <p>
        Once you have been chosen to make quotes for a User, you
        have permission, by that User and this site, to that User’s Personal Data. This
        allows you and your respective Carrier(s) to access the User’s personal records
        that pertains to the type of insurance they are requesting (like CLUE for Auto and
        Home; and Medical Records for Life and Health). Also, you have permission to
        provide the User’s Personal Data to the Carriers for which you are obtaining
        quotes.
      </p>
      
      <h6>5. Data Usage</h6>
      <p>
        Data is used for the purpose of creating accurate insurance quotes
        and policies (refer to Section 8, Data Distribution, for more). Otherwise, the Data
        stored on this site is for you to reference and update.
      </p>
      
      <h6>6. Data Protection</h6>
      <p>
        We use a series of firewalls and other security measures in
        order to keep your Data and ours safe from malicious or unwarranted attacks or
        breaches. We recommend that you also have some type of internet security or
        firewall set for everyone’s safety.
      </p>
      <h6>7. Use of cookies</h6>
      <p>
        We do not collect or store cookies. We might in the future only if
        it benefits you for us to do so. We do not use them for spamming.
      </p>
      <h6>8. Data Distribution</h6>
      <p>
        We do not share your Personal Data with a third party. You
        are responsible for only sharing User’s Data (Personal or otherwise) for the sole
        purpose of obtaining records that are absolutely necessary and sending the Data
        to your respective Carrier(s) in order to create quotes and policies. After quotes
        have been made - or if you have been selected by the User to write a policy and
        it has been signed by the User - you are to properly discard and/or shred any
        records or Data collected from us, about the User. Utilizing User’s Data for any
        purpose other than to provide Users with accurate quotes, policies, or for
        advising the User is strictly prohibited and will result in fines of no less than $500
        per occurrence and possible legal action. Note that you will be able to reference
        the User’s Data that you entered into the site, as long as you are their Agent for
        the respective policy for which you have access. Demographic Data can be used
        or sold for the purpose of advancing Carriers or Agencies that would do well in
        certain areas.
      </p>
      <h6>9. Privacy Policy Consent</h6>
      <p>
        By using this site, you agree to our Privacy Policy
      </p>
      <h6>10. Terms and Conditions Consent</h6>
      <p>
        By using this site you agree to our Terms and Conditions
      </p>
      <h6>11. Changes to Policy</h6>
      <p>
        We hold the right to make changes to any of our policies,
        located in our Terms and Conditions or our Privacy Policy. Any policy change
        supersedes any policy created prior to said change. Changes that affect the
        Agents of this site will be posted and available to be viewed at any time at the
        bottom of the Agent’s dashboard. We will also notify you of changes and likely
        have you agree to them before you will be able to continue using the site.
      </p>
      <h6>12.Contact Information</h6>
    </>
  );
};

export default AgentPrivacy;