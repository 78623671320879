import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertAgentAnimalQuoteFromDBSave } from "./companion-animal.utils";
import agentCompanionAnimalQuoteActionTypes from "./companion-animal.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  agentAnimalQuoteSaveSuccess,
  agentAnimalQuoteSaveFailure,
  fetchAgentAnimalQuoteFromDBSuccess,
  fetchAgentAnimalQuoteFromDBFailure,
  agentAnimalQuoteUpdateSuccess,
  agentAnimalQuoteUpdateFailure,
  agentAnimalQuoteDeleteSuccess,
  agentAnimalQuoteDeleteFailure,
} from "./companion-animal.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchAgentAnimalQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/agent-animal-quote/${id}`);
    const annuityPolicy = yield response.data;
    yield put(fetchAgentAnimalQuoteFromDBSuccess(annuityPolicy));
  } catch (e) {
    yield put(fetchAgentAnimalQuoteFromDBFailure(e));
  }
}

function* saveAgentAnimalQuote({ payload: formData }) {
  try {
    const data = yield call(convertAgentAnimalQuoteFromDBSave, formData);
    const formDataFiles = new FormData();
    for (var i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let result = response.data ? response.data.result : [];
      let chunks = response.data ? response.data.chunks : [];
      if (result && chunks) {
        for (let m = 0; m < result.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === result[m]._id
          );
          result[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(result) ? result : [];
    });
    yield axios.post(`/api/agent-animal-quote`, data, configureAxios());
    yield put(agentAnimalQuoteSaveSuccess());
  } catch (e) {
    yield put(agentAnimalQuoteSaveFailure(e));
  }
}

function* updateAgentAnimalQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAgentAnimalQuoteFromDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length !== 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let result = response.data ? response.data.result : [];
          let chunks = response.data ? response.data.chunks : [];
          if (result && chunks) {
            for (let m = 0; m < result.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === result[m]._id
              );
              result[m].chunkId = chunks[index]._id;
            }
          }
          if (data.quoteDetails && data.quoteDetails.isSelected === true) {
            data.polDocuments = Array.isArray(result)
              ? data.polDocuments.concat(result)
              : [];
          } else {
            data.documents = Array.isArray(result)
              ? data.documents.concat(result)
              : [];
          }
        });
      yield axios.patch(
        `/api/agent-animal-quote/${id}`,
        data,
        configureAxios()
      );
      yield put(agentAnimalQuoteUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      yield axios.patch(
        `/api/agent-animal-quote/${id}`,
        data,
        configureAxios()
      );
      yield put(agentAnimalQuoteUpdateSuccess());
    }
  } catch (e) {
    yield put(agentAnimalQuoteUpdateFailure(e));
  }
}

function* deleteAgentAnimalQuote({ payload: { id, pId } }) {
  try {
    axios.delete(
      `/api/agent-animal-quote/${id}`,
      { params: { primeMemberId: pId } },
      configureAxios()
    );
    yield put(agentAnimalQuoteDeleteSuccess());
  } catch (e) {
    yield put(agentAnimalQuoteDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    agentCompanionAnimalQuoteActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchAgentAnimalQuoteFromDB() {
  yield takeEvery(
    agentCompanionAnimalQuoteActionTypes.FETCH_AGENT_COMPANION_ANIMAL_QUOTE_FROM_DB_START,
    fetchAgentAnimalQuoteFromDB
  );
}

function* onAgentAnimalQuoteSave() {
  yield takeEvery(
    agentCompanionAnimalQuoteActionTypes.START_AGENT_COMPANION_ANIMAL_QUOTE_SAVE,
    saveAgentAnimalQuote
  );
}

function* onAgentAnimalQuoteUpdate() {
  yield takeEvery(
    agentCompanionAnimalQuoteActionTypes.START_AGENT_COMPANION_ANIMAL_QUOTE_UPDATE,
    updateAgentAnimalQuote
  );
}

function* onDeleteAgentAnimalQuote() {
  yield takeEvery(
    agentCompanionAnimalQuoteActionTypes.START_AGENT_ANIMAL_QUOTE_DELETE,
    deleteAgentAnimalQuote
  );
}

export function* agentAnimalQuoteSagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onAgentAnimalQuoteSave),
    call(onFetchAgentAnimalQuoteFromDB),
    call(onAgentAnimalQuoteUpdate),
    call(onDeleteAgentAnimalQuote),
  ]);
}
