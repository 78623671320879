import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertAgentAnnuityQuoteFromDBSave } from "./annuity.utils";
import agentAnnuityQuoteActionTypes from "./annuity.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  agentAnnuityQuoteSaveSuccess,
  agentAnnuityQuoteSaveFailure,
  fetchAgentAnnuityQuoteFromDBSuccess,
  fetchAgentAnnuityQuoteFromDBFailure,
  agentAnnuityQuoteUpdateSuccess,
  agentAnnuityQuoteUpdateFailure,
  agentAnnuityQuoteDeleteSuccess,
  agentAnnuityQuoteDeleteFailure,
} from "./annuity.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchAgentAnnuityQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/agent-annuity-quote/${id}`);
    const annuityPolicy = yield response.data;
    yield put(fetchAgentAnnuityQuoteFromDBSuccess(annuityPolicy));
  } catch (e) {
    yield put(fetchAgentAnnuityQuoteFromDBFailure(e));
  }
}

function* saveAgentAnnuityQuote({ payload: formData }) {
  try {
    const data = yield call(convertAgentAnnuityQuoteFromDBSave, formData);
    const formDataFiles = new FormData();
    for (var i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let result = response.data ? response.data.result : [];
      let chunks = response.data ? response.data.chunks : [];
      if (result && chunks) {
        for (let m = 0; m < result.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === result[m]._id
          );
          result[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(result) ? result : [];
    });
    yield axios.post(`/api/agent-annuity-quote`, data, configureAxios());
    yield put(agentAnnuityQuoteSaveSuccess());
  } catch (e) {
    yield put(agentAnnuityQuoteSaveFailure(e));
  }
}

function* updateAgentAnnuityQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAgentAnnuityQuoteFromDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length > 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let result = response.data ? response.data.result : [];
          let chunks = response.data ? response.data.chunks : [];
          if (result && chunks) {
            for (let m = 0; m < result.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === result[m]._id
              );
              result[m].chunkId = chunks[index]._id;
            }
          }
          if (data.quoteDetails && data.quoteDetails.isSelected === true) {
            data.polDocuments = Array.isArray(result)
              ? data.polDocuments.concat(result)
              : [];
          } else {
            data.documents = Array.isArray(result)
              ? data.documents.concat(result)
              : [];
          }
        });
      yield axios.patch(
        `/api/agent-annuity-quote/${id}`,
        data,
        configureAxios()
      );
      yield put(agentAnnuityQuoteUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      axios.patch(`/api/agent-annuity-quote/${id}`, data, configureAxios());
      yield put(agentAnnuityQuoteUpdateSuccess());
    }
  } catch (e) {
    yield put(agentAnnuityQuoteUpdateFailure(e));
  }
}

function* deleteAgentAnnuityQuote({ payload: { id, pId } }) {
  try {
    axios.delete(
      `/api/agent-annuity-quote/${id}`,
      { params: { primeMemberId: pId } },
      configureAxios()
    );
    yield put(agentAnnuityQuoteDeleteSuccess());
  } catch (e) {
    yield put(agentAnnuityQuoteDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    agentAnnuityQuoteActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchAgentAnnuityQuoteFromDB() {
  yield takeEvery(
    agentAnnuityQuoteActionTypes.FETCH_AGENT_ANNUITY_QUOTE_FROM_DB_START,
    fetchAgentAnnuityQuoteFromDB
  );
}

function* onAgentAnnuityQuoteSave() {
  yield takeEvery(
    agentAnnuityQuoteActionTypes.START_AGENT_ANNUITY_QUOTE_SAVE,
    saveAgentAnnuityQuote
  );
}

function* onAgentAnnuityQuoteUpdate() {
  yield takeEvery(
    agentAnnuityQuoteActionTypes.START_AGENT_ANNUITY_QUOTE_UPDATE,
    updateAgentAnnuityQuote
  );
}

function* onDeleteAgentAnnuityQuote() {
  yield takeEvery(
    agentAnnuityQuoteActionTypes.START_AGENT_ANNUITY_QUOTE_DELETE,
    deleteAgentAnnuityQuote
  );
}

export function* agentAnnuityQuoteSagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onAgentAnnuityQuoteSave),
    call(onFetchAgentAnnuityQuoteFromDB),
    call(onAgentAnnuityQuoteUpdate),
    call(onDeleteAgentAnnuityQuote),
  ]);
}
