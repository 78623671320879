import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertMemberComapanionAnimalPolicyForDBSave } from "./companion-animal.utils";
import memberCompanionAnimalPolicyActionTypes from "./companion-animal.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  memberCompanionAnimalPolicySaveSuccess,
  memberCompanionAnimalPolicySaveFailure,
  fetchAnimalsFromDBSuccess,
  fetchAnimalsFromDBFailure,
  fetchCompanionAnimalFromDBSuccess,
  fetchCompanionAnimalFromDBFailure,
  companionAnimalPolicyUpdateSuccess,
  companionAnimalPolicyUpdateFailure,
  companionAnimalPolicyDeleteSuccess,
  companionAnimalPolicyDeleteFailure,
} from "./companion-animal.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchAnimalsFromDB() {
  try {
    const response = yield axios.get(`/api/companion-animals`);
    const animal = yield response.data;
    yield put(fetchAnimalsFromDBSuccess(animal));
  } catch (e) {
    yield put(fetchAnimalsFromDBFailure(e));
  }
}

function* fetchAnimalPolicyFromDB({ payload: id }) {
  try {
    const response = yield axios.get(
      `/api/member-companion-animal-policy/${id}`
    );
    const animalPolicy = yield response.data;
    yield put(fetchCompanionAnimalFromDBSuccess(animalPolicy));
  } catch (e) {
    yield put(fetchCompanionAnimalFromDBFailure(e));
  }
}

function* saveMemberCompanionAnimalPolicy({ payload: formData }) {
  try {
    const data = yield call(
      convertMemberComapanionAnimalPolicyForDBSave,
      formData
    );
    const formDataFiles = new FormData();
    for (var i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let result = response.data ? response.data.result : [];
      let chunks = response.data ? response.data.chunks : [];
      if (result && chunks) {
        for (let m = 0; m < result.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === result[m]._id
          );
          result[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(result) ? result : [];
      axios.post(`/api/member-companion-animal-policy`, data, configureAxios());
    });
    yield put(memberCompanionAnimalPolicySaveSuccess());
  } catch (e) {
    yield put(memberCompanionAnimalPolicySaveFailure(e));
  }
}

function* updateCompanionAnimalPolicy({ payload: { id, formData } }) {
  try {
    const data = yield call(
      convertMemberComapanionAnimalPolicyForDBSave,
      formData
    );
    if (formData.uploadedFiles && formData.uploadedFiles.length !== 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let result = response.data ? response.data.result : [];
          let chunks = response.data ? response.data.chunks : [];
          if (result && chunks) {
            for (let m = 0; m < result.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === result[m]._id
              );
              result[m].chunkId = chunks[index]._id;
            }
          }
          data.documents = Array.isArray(result)
            ? data.documents.concat(result)
            : [];
          axios.patch(
            `/api/member-companion-animal-policy/${id}`,
            data,
            configureAxios()
          );
        });
      yield put(companionAnimalPolicyUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      axios.patch(
        `/api/member-companion-animal-policy/${id}`,
        data,
        configureAxios()
      );
      yield put(companionAnimalPolicyUpdateSuccess());
    }
  } catch (e) {
    yield put(companionAnimalPolicyUpdateFailure(e));
  }
}

function* deleteCompanionAnimalPolicy({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/member-companion-animal-policy/${id}`,
      { params: { primeMemberId: PId } },
      configureAxios()
    );
    yield put(companionAnimalPolicyDeleteSuccess());
  } catch (e) {
    yield put(companionAnimalPolicyDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    memberCompanionAnimalPolicyActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchAnimalsFromDB() {
  yield takeEvery(
    memberCompanionAnimalPolicyActionTypes.FETCH_ANIMALS_FROM_DB_START,
    fetchAnimalsFromDB
  );
}

function* onFetchAnimalPolicyFromDB() {
  yield takeEvery(
    memberCompanionAnimalPolicyActionTypes.FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_START,
    fetchAnimalPolicyFromDB
  );
}

function* onMemberCompanionAnimalPolicySaveStart() {
  yield takeEvery(
    memberCompanionAnimalPolicyActionTypes.START_COMPANION_ANIMAL_POLICY_SAVE,
    saveMemberCompanionAnimalPolicy
  );
}

function* onUpdateCompanionAnimalPolicy() {
  yield takeEvery(
    memberCompanionAnimalPolicyActionTypes.START_COMPANION_ANIMAL_POLICY_UPDATE,
    updateCompanionAnimalPolicy
  );
}

function* onDeleteCompanionAnimalPolicy() {
  yield takeEvery(
    memberCompanionAnimalPolicyActionTypes.START_COMPANION_POLICY_DELETE,
    deleteCompanionAnimalPolicy
  );
}

export function* memberCompanionAnimalPolicySagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onFetchAnimalsFromDB),
    call(onMemberCompanionAnimalPolicySaveStart),
    call(onFetchAnimalPolicyFromDB),
    call(onUpdateCompanionAnimalPolicy),
    call(onDeleteCompanionAnimalPolicy),
  ]);
}
