import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertAgentPropertyQuoteFromDBSave } from "./property.utils";
import agentPropertyQuoteActionTypes from "./property.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  agentPropertyQuoteSaveSuccess,
  agentPropertyQuoteSaveFailure,
  fetchAgentPropertyQuoteFromDBSuccess,
  fetchAgentPropertyQuoteFromDBFailure,
  agentPropertyQuoteUpdateSuccess,
  agentPropertyQuoteUpdateFailure,
  agentPropertyQuoteDeleteSuccess,
  agentPropertyQuoteDeleteFailure,
} from "./property.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchAgentPropertyQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/agent-property-quote/${id}`);
    const propertyQuote = yield response.data;
    yield put(fetchAgentPropertyQuoteFromDBSuccess(propertyQuote));
  } catch (e) {
    yield put(fetchAgentPropertyQuoteFromDBFailure(e));
  }
}

function* saveAgentPropertyQuote({ payload: formData }) {
  try {
    const data = yield call(convertAgentPropertyQuoteFromDBSave, formData);
    const formDataFiles = new FormData();
    for (var i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let result = response.data ? response.data.result : [];
      let chunks = response.data ? response.data.chunks : [];
      if (result && chunks) {
        for (let m = 0; m < result.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === result[m]._id
          );
          result[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(result) ? result : [];
    });
    yield axios.post(`/api/agent-property-quote`, data, configureAxios());
    yield put(agentPropertyQuoteSaveSuccess());
  } catch (e) {
    yield put(agentPropertyQuoteSaveFailure(e));
  }
}

function* updateAgentPropertyQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAgentPropertyQuoteFromDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length > 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let result = response.data ? response.data.result : [];
          let chunks = response.data ? response.data.chunks : [];
          if (result && chunks) {
            for (let m = 0; m < result.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === result[m]._id
              );
              result[m].chunkId = chunks[index]._id;
            }
          }
          if (data.quoteDetails && data.quoteDetails.isSelected === true) {
            data.polDocuments = Array.isArray(result)
              ? data.polDocuments.concat(result)
              : [];
          } else {
            data.documents = Array.isArray(result)
              ? data.documents.concat(result)
              : [];
          }
        });
      yield axios.patch(
        `/api/agent-property-quote/${id}`,
        data,
        configureAxios()
      );
      yield put(agentPropertyQuoteUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      yield axios.patch(
        `/api/agent-property-quote/${id}`,
        data,
        configureAxios()
      );
      yield put(agentPropertyQuoteUpdateSuccess());
    }
  } catch (e) {
    yield put(agentPropertyQuoteUpdateFailure(e));
  }
}

function* deleteAgentPropertyQuote({ payload: { id, pId } }) {
  try {
    axios.delete(
      `/api/agent-property-quote/${id}`,
      { params: { primeMemberId: pId } },
      configureAxios()
    );
    yield put(agentPropertyQuoteDeleteSuccess());
  } catch (e) {
    yield put(agentPropertyQuoteDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    agentPropertyQuoteActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchAgentPropertyQuoteFromDB() {
  yield takeEvery(
    agentPropertyQuoteActionTypes.FETCH_AGENT_PROPERTY_QUOTE_FROM_DB_START,
    fetchAgentPropertyQuoteFromDB
  );
}

function* onAgentPropertyQuoteSave() {
  yield takeEvery(
    agentPropertyQuoteActionTypes.START_AGENT_PROPERTY_QUOTE_SAVE,
    saveAgentPropertyQuote
  );
}

function* onAgentPropertyQuoteUpdate() {
  yield takeEvery(
    agentPropertyQuoteActionTypes.START_AGENT_PROPERTY_QUOTE_UPDATE,
    updateAgentPropertyQuote
  );
}

function* onDeleteAgentPropertyQuote() {
  yield takeEvery(
    agentPropertyQuoteActionTypes.START_AGENT_PROPERTY_QUOTE_DELETE,
    deleteAgentPropertyQuote
  );
}

export function* agentPropertyQuoteSagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onAgentPropertyQuoteSave),
    call(onFetchAgentPropertyQuoteFromDB),
    call(onAgentPropertyQuoteUpdate),
    call(onDeleteAgentPropertyQuote),
  ]);
}
